<template>
<div style="position: relative; margin-top: 35px;">
    <v-btn text v-show="false" @click="ok" data-hack="ok"></v-btn>

    <v-container style="max-width: none;" class="filters pa-0">
        <div class="flex-filter d-none d-md-flex d-lg-flex">
            <div class="flex-filter-item">
                <v-btn text @click="ui.filters=!ui.filters" class="elevation-0 filter-button">
                    <v-icon left>mdi-filter</v-icon>
                    <Str index="buttons.filters._text"/>
                </v-btn>
            </div>
            <div class="flex-filter-item">
                <v-btn @click="resetFilters" class="elevation-0 filter-button-clear">
                    <v-icon left>mdi-broom</v-icon>
                    <Str index="buttons.clear._text"/>
                </v-btn>
            </div>
            <div class="flex-filter-item">
                <v-text-field outlined v-model="filters.search.events" clearable :append-icon="'mdi-magnify'" @click:append="$emit('filter')">
                    <template v-slot:label>
                        <str index="events.filters.keyword_search"/>
                    </template>
                </v-text-field>
            </div>
            <div class="flex-filter-item">
                <div class="container-4">
                    <v-select background-color="white" solo flat v-model="filters.sortBy" dense outlined :items="sortItems" :append-icon="'mdi-sort'">
                        <template v-slot:label>
                            <str index="events.filters.sort_events"/>
                        </template>
                    </v-select>
                </div>
            </div>
        </div>


        <div class="flex-filter-mobile d-flex d-none d-sm-flex d-md-none">
            <div class="flex-filter-item">
                <v-btn text @click="ui.filters=!ui.filters" class="elevation-0 filter-button">
                    <!-- <v-icon left>mdi-filter</v-icon> -->
                    <Str index="buttons.filters"/>
                </v-btn>
            </div>

            <div class="flex-filter-item">
                <v-btn @click="resetFilters" class="elevation-0 filter-button-clear">
                    <!-- <v-icon left>mdi-broom</v-icon> -->
                    <Str index="buttons.clear"/>
                </v-btn>
            </div>

            <div class="flex-filter-item sortEventsBy_button">
                <v-select class="filter-select" background-color="white" solo flat v-model="filters.sortBy" dense outlined :items="sortItems" :append-icon="'mdi-sort'">
                    <template v-slot:label>
                        <str index="events.filters.sort_events"/>
                    </template>
                </v-select>
            </div>


            <div class="flex-filter-item">
                <!-- <v-combobox
                    background-color="white"
                    v-model="search.events"
                    :items="autocompleteItems"
                    :append-icon="'mdi-magnify'"
                    :validate-on-blur="true"
                    clearable
                    outlined
                    :label="$store.getters.labels.filter.keyword_search._text[language]"
                    v-on:keyup.enter="onEnter"
                >
                <template v-slot:selection="data">
                    {{data.item.value ? data.item.value : data.item}}
                </template>

                <template v-slot:item="data">
                    <v-list-item-content>
                        <v-list-item-title v-if="data.item.type=='program'" v-html="data.item.value ? data.item.value : data.item"></v-list-item-title>
                        <v-list-item-subtitle v-if="data.item.type=='event'" v-html="data.item.value ? data.item.value : data.item"></v-list-item-subtitle>
                    </v-list-item-content>
                </template>


                </v-combobox>     -->
                <v-text-field outlined v-model="filters.search.events" clearable :append-icon="'mdi-magnify'" @click:append="$emit('filter')">
                    <template v-slot:label>
                        <str index="events.filters.keyword_search"/>
                    </template>
                </v-text-field>
            </div>
        </div>

        <!-- <v-row class="d-flex d-none d-sm-flex d-md-none" align="center">
            <v-col cols="4" class="pa-0" align="center">
                <v-btn text @click="ui.filters=!ui.filters">
                    <v-icon left>mdi-filter</v-icon>
                    Filter(s)
                </v-btn>
            </v-col>
            <v-col cols="4" class="pa-0" align="center">
                <v-btn text @click="resetFilters">
                    <v-icon>mdi-broom</v-icon>
                    Clear
                </v-btn>
            </v-col>
            <v-col cols="4" class="pa-0" align="center">
                <v-select background-color="white" v-model="filters.sortBy" dense outlined :label="$store.getters.labels.filter.sort_events[language]" :items="sortItems" :append-icon="'mdi-sort'"/>
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <v-combobox
                    v-model="search.events"
                    :items="autocompleteItems"
                    :append-icon="'mdi-magnify'"
                    :validate-on-blur="true"
                    :filled="true"
                    dense
                    outlined
                    clearable
                    label="Search events for a keyword"
                    v-on:keyup.enter="onEnter"
                    color="white"
                    dark
                >
                <template v-slot:selection="data">
                    {{data.item.value ? data.item.value : data.item}}
                </template>

                <template v-slot:item="data">
                    <v-list-item-content>
                        <v-list-item-title v-if="data.item.type=='program'" v-html="data.item.value ? data.item.value : data.item"></v-list-item-title>
                        <v-list-item-subtitle v-if="data.item.type=='event'" v-html="data.item.value ? data.item.value : data.item"></v-list-item-subtitle>
                    </v-list-item-content>
                </template>


                </v-combobox>
            </v-col>
        </v-row> -->
    </v-container>

    <v-navigation-drawer
        v-model="ui.filters"
        app
        class="filter-drawer"
        dark
        fixed
        temporary
        width="350"
        :hide-overlay="true"
        >
        <v-list-item>
        <v-list-item-avatar>
            <v-icon size="27">mdi-filter-menu-outline</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
                <str index="events.filters.title"/>
            </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn icon @click="ui.filters=!ui.filters">
                    <v-icon size="27">mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>
            <v-list-item class="justify-center" style='color:#e1e1e1;'>
                <str index="events.filters.subtitle"/>
            </v-list-item>
        <v-divider></v-divider>

        <v-list dense>
            <v-list-item v-for="filter in config.filters" :key="'filter_'+filter.target">
                <v-list-item-content class="pa-2" style="cursor: pointer;" @click="filters[filter.target]=!filters[filter.target]">
                    <span style="color: white !important;">
                        <str :index="filter.label"/>
                    </span>
                </v-list-item-content>

                 <v-list-item-action>
                    <v-switch inset :true-value="false" :false-value="true" v-model="filters[filter.target]">
                        <template slot="label">
                             <!-- <span style="color: white !important;" v-html="filter.label"/> -->
                        </template>
                    </v-switch>
                 </v-list-item-action>
            </v-list-item>
        </v-list>


        <v-divider></v-divider>

        <v-list-item>
            <v-list-item-content>
                <v-expansion-panels light v-model="ui.panels">
                    <v-expansion-panel
                        expand
                        v-model="ui.panels">
                    <v-expansion-panel-header>
                        <span><str index="events.filters.between"/>:</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-date-picker
                            v-model="filters.dates"
                            range
                            width="100%"
                        ></v-date-picker>
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-list-item-content>
        </v-list-item>





        <v-divider></v-divider>

    </v-navigation-drawer>

</div>
</template>

<script>
export default {
    props: {
        autocompleteItems: {
            type: Array,
            required: false,
            default: ()=>{return []}
        },
        language: {
            type: String,
            required: true
        }
    },
    // created: function(){
    //     this.$emit('input',this.form.filters)
    // },
    data: function(){
        return {
            timers: {
                search: null,
                hack: null
            },
            ui: {
                filters: false,
                dates: false,
                panels: 0
            },
            search: {
                events: null,
                programs: null
            },
            // form: {
            //     filters: {
            //         sortBy: null,
            //         search: {
            //             events: null,
            //             programs: null
            //         },
            //         events: null,
            //         enrolled: false,
            //         upcoming: true,
            //         past: false,
            //         dates: []
            //     }
            // }
        }
    },
    methods: {
        resetFilters: function(){
            this.search.events = null
            this.search.programs = null
            for(let i in this.filters){
                if(this.filters[i] !=null && typeof this.filters[i]=='object'){
                    this.filters[i] = []
                }else{
                    if(i=='upcoming'){
                        this.filters[i] = true
                    }else if(i!='search'){
                        this.filters[i] = false
                    }
                }
            }
            this.$emit('clear')
        },
        onEnter: function(e){
            let self = this
            clearTimeout(self.timers.hack)
            self.timers.hack = setTimeout(function(){
                console.log('enter key pressed',e)
                document.querySelector("[data-hack='ok']").click()
            },800)
        },
        ok: function(){
            console.log('Okayed')
            //Just removes focus so silly combobox menu goes away
        }
    },
    computed: {
        sortItems: function(){
            let output = [
                // {text: 'Program: A-Z', value: 'Program: A-Z'},
                // {text: 'Program: Z-A', value: 'Program: Z-A'},
                {text: this.$store.getters.labels.events.filters.sorter.a_z._text[this.language], value: 'title_ascending'},
                {text: this.$store.getters.labels.events.filters.sorter.z_a._text[this.language], value: 'title_descending'},
                {text: this.$store.getters.labels.events.filters.sorter.newest._text[this.language], value: 'newest'},
                {text: this.$store.getters.labels.events.filters.sorter.oldest._text[this.language], value: 'oldest'},
            ]
            return output
        },
        config: function(){
            let filters = [
            ]

            if(this.$store.getters.signedIn){
                filters.push({target:'upcoming',icon:'mdi-calendar-clock-outline',label:'events.filters.other_upcoming'})
                filters.push({target:'past',icon:'mdi-history',label:'events.filters.other_past'})
            }

            // filters: [
                // {target:'enrolled',icon:'mdi-ticket-confirmation-outline',label:'Enrolled'},
                // {target:'today',icon:'mdi-clock-time-four-outline',label:'Today'},
                // {target:'thisWeek',icon:'mdi-timer-sand',label:'This Week'},
            // ]

            return {
                filters
            }
        },
        filters: function(){
            return this.$attrs.value
        }
    },
    watch: {
        // form: {
        //     deep: true,
        //     handler: function(){
        //         this.$emit('input',this.form.filters)
        //     }
        // },
        // search: {
        //     deep: true,
        //     handler: function(){
        //         let self = this
        //         clearTimeout(this.timers.search)
        //         this.timers.search = setTimeout(function(){
        //             self.form.filters.search = self.search
        //         },500)
        //     }
        // }
    }
}
</script>

<style scoped lang="scss">
.flex-filter, .flex-filter-mobile{
    display: flex;
    background-color: $primary-light-1;
    width: 100%;
    height: auto;
    align-items: stretch;
}
.flex-filter-item{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 70px;
}
.flex-filter .flex-filter-item:nth-child(1){
    width: 20%;
}
.flex-filter .flex-filter-item:nth-child(2){
    background-color: white;
    width: 10%;
}
.flex-filter .flex-filter-item:nth-child(3){
    background-color: white;
    padding: 50px 1% 20px 1%;
    width: 50%;
    text-align: center;
}
.flex-filter .flex-filter-item:nth-child(4){
    background-color: white;
    padding: 0px 0px 0px 1%;
    width: 30%;
}

.container-4{
    width: 100%;
    height: 70px;
    background-color: $primary-light-1;
    padding: 15px 50px 20px 50px;

}

.flex-filter-mobile{
    height: 100px;
    flex-wrap: wrap;
}
.flex-filter-mobile .flex-filter-item:nth-child(1){
    width: 33%;
}
.flex-filter-mobile .flex-filter-item:nth-child(2){
    background-color: $secondary;
    width: 33%;
}
.flex-filter-mobile .flex-filter-item:nth-child(3){
    width: 33%;
    justify-content: center;
}
.flex-filter-mobile .flex-filter-item:nth-child(4){
    background-color: white;
    padding: 30px 0px 0px 0px;
    width: 100%;
}

.flex-filter-mobile .filter-select{
    position: relative;
    top: 20%;
}

.filters{
    background-color: $primary-light-1;
    color: white;
    width: 100%;
}

.filters button{
    background-color: $primary-light-1;
    color: white !important;
}

.filter-button{
    height: 70px;
    width: 100%;
    border-radius: 0px;
}

.v-btn.filter-button-clear{
    background-color: $secondary !important;
    height: 70px;
    border-radius: 0px;
}
    .flex-filter-mobile .v-btn.filter-button-clear{
        height: 50px;
    }

.filter-drawer{
    // top: 430px !important;
    background-color: $primary-light-1 !important;
    padding: 5px 11px;
}

@media only screen and (max-width: 800px){


}
</style>

<style lang='scss'>
    .filters {

        .mdi-sort {
            @media(max-width: 600px){
                display: none;
            }
        }

        .flex-filter-item.sortEventsBy_button {
            @media(max-width: 450px){

                .v-input__slot {
                    max-height: 60px;
                    padding: 0 4px !important;
                }

                .v-select__slot {
                    display: block;

                    label {
                        top: 8px;
                        overflow: visible;
                        white-space: initial;
                        text-align: center;
                    }

                    .v-select__selection {
                        width: 100%;
                        margin: 3px;
                        overflow: visible;
                        white-space: initial;
                        text-align: center;
                    }
                }
            }
        }
    }
</style>
