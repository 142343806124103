<template>
    <v-card flat>
        <v-btn text @click="$router.push({name:'Events',params:{language}})">
            <v-icon x-small class="carrot-left">mdi-triangle</v-icon>
            <str index="buttons.back"/>
        </v-btn>
        <div class="eventDetails-title">
            <h3 class="eventDetails-title-content">{{eventData.title[language]}}</h3>
        </div>

       <component v-bind:is="'Section'+section" v-for="section in sections" :key="section" :eventData="eventData" :speakers="speakers" :language="language"/>


        <!-- <v-divider/> -->

        <v-card-actions>
            <EventActions 
                :eventData="eventData" 
                :debug="debug" 
                :enrolled="enrolled" 
                :buttons="buttons"
                :language="language"
                :bookings="bookings"
                :options="options"
            >             
                <template v-slot:actionButtons>
                    <slot name="actionButtons"/>
                </template>
            </EventActions>            
        </v-card-actions>
        <div v-if="enrolled" style="z-index: 1;padding-bottom: 25px; text-align: center;">
            <EventResources 
                :eventData="eventData" 
                :enrolled="enrolled" 
                :buttons="buttons" 
                :missingEval="missingEval" 
                :language="language"
            >
                <template v-slot:resourceButtons>
                    <slot name="resourceButtons"/>
                </template>
            </EventResources>            
        </div>
        
        <v-card-actions>
            <v-spacer/>
                <v-btn text @click="$router.push({name:'Events',params:{language}})">
                    <v-icon x-small class="carrot-left">mdi-triangle</v-icon>
                    <str index="buttons.back"/>            
                </v-btn>            
            <v-spacer/>
        </v-card-actions>
    </v-card>
</template>

<script>
import EventActions from "@/components/Events/EventActions.vue"
import EventResources from "@/components/Events/EventResources.vue"
import SectionStartTime from "./lib/EventDetailsStartTime"
import SectionLearningObjectives from "./lib/EventDetailsLearningObjectives"
import SectionSpeakers from "./lib/EventDetailsSpeakers"
import SectionIntroduction from "./lib/EventDetailsIntroduction" 

export default {
    props: {
        eventData: {
            type: Object,
            required: true
        },
        enrolled: {
            type: Boolean,
            required: true
        },
        enrollmentRecord: {
            type: Object,
            required: false
        },
        missingEval: {
            type: Boolean,
            required: true
        },
        speakers: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        buttons: {
            type: Object,
            required: false,
            default: function(){
                return {}
            }
        },
        sections: {
            type: Array,
            required: false,
            default: function(){
                let output = [
                    'Introduction',
                    'StartTime',
                    'LearningObjectives',
                    'Speakers',
                ]

                return output
            }
        },
        bookings: {
            type: Number,
            required: true
        },
        options: {
            type: Object,
            required: false,
            default: function(){
                return {}
            }
        }
    },
    components: {
        EventActions,
        EventResources,
        SectionStartTime,
        SectionLearningObjectives,
        SectionSpeakers,
        SectionIntroduction        
    },
    mounted: function(){
        let element = document.querySelector(".eventDetails-title");
        element.scrollIntoView();
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        }
    }
}
</script>

<style lang="scss">
.carrot-down{
    color: $secondary !important;
    display: block !important;
    transform:rotate(180deg);
}

.carrot-left{
    transform:rotate(-90deg);
    margin: 15px;
}
</style>


<style scoped lang="scss">
h3{
    color: white;
}

.eventDetails-title{
    background-color: $primary-light-1;
    color: white;
    height: 100px;
    display: flex;
    justify-content: center;
}

.eventDetails-title-content{
    align-self: center;
}

.eventDetails-startTime{
    text-align: center;
    margin: 25px;
    font-weight: bold;
}

.event-detail-icon{
    background-color: $primary-light-1;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    z-index: 2;
    color: white;
    margin: 15px;
    position: relative;
    top: -5px;
}

.eventDetails-startTime-box{
    margin: 15px auto;
    border: 1px solid lightgrey;
    max-width: 360px;
    padding: 25px;
    background: rgb(249,249,249);
    background: linear-gradient(0deg, rgba(249,249,249,1) 0%, rgba(245,245,245,1) 35%, rgba(255,255,255,1) 100%);
    color: $primary-light-1;
}
    .eventDetails-startTime-box .v-icon{
        color: $primary-light-1;
        margin: 5px;
    }




.eventDetails-learningObjectives{
    color: white;
    background-color: $primary-light-1;
    width: 100%;
    padding: 25px;
    min-height: 200px;
    display: flex;
    justify-content: center;;
}

.learningObjectives-col{
    font-size: 16pt;
    width: 50%;
}
    .learningObjectives-col:first-child{
        display: flex;
        justify-content: end;
    }

.decoration{
    background-size: cover;
    display: flex;
    justify-self: stretch;
    width: 200px;
    margin-right: 60px;
}







.eventDetails-faculty{
    background-color: whitesmoke;
    margin: 25px;
    padding: 25px;
    border-radius: 15px;
    text-align: center;
}

.eventDetails-faculty-box{
    margin: 25px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2em;
}

    .faculty-card{
        display: flex;
        width: 30%;
        gap: 2em;
        justify-content: space-evenly;
        background-color: white;
        padding: 25px;
    }

    .faculty-card-col{
        align-self: center;
    }

    .eventDetails-faculty-box .v-avatar{
        border: 2px solid $primary;
        background-color: $primary-light-4 !important;
        color: white;
        margin: 0px 15px 0px 15px;
    }
        .enrolled .eventDetails-faculty-box .v-avatar{
            border: 5px solid $secondary;
        }

.carrot-down{
    color: $secondary;
    display: block;
    transform:rotate(180deg);
}

.carrot-left{
    transform:rotate(-90deg);
    margin: 15px;
}
</style>