<template>
<div class="eventDetails-speakers">
    <v-icon class="event-detail-icon">mdi-account-voice</v-icon>
    <span style="font-size: 17pt;font-weight: bold;">
        <str v-if="eventData.speakers.length==1" index="events.details.speaker"/>
        <str v-if="eventData.speakers.length>1" index="events.details.speakers"/>
    </span>
    <v-icon x-small class="carrot-down">mdi-triangle</v-icon>

    <div class="eventDetails-speakers-container">

        <div v-for="(speakerID) in eventData.speakers" :key="'speaker_member_'+speakerID" class="speaker-col">
            <SpeakerCard :speaker="speakers[speakerID]" :language="language" style="width: 100%;"/>
        </div>

    </div>
</div>
</template>

<script>
import SpeakerCard from "./SpeakerCard.vue"
export default {
    props: {
        eventData: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        speakers: {
            type: Object,
            required: true
        }
    },
    components: {
        SpeakerCard
    }
}
</script>


<style scoped lang="scss">
.event-detail-icon{
    background-color: $primary-light-1;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    z-index: 2;
    color: white;
    margin: 15px;
    position: relative;
    top: -5px;
}

.eventDetails-speakers{
    background-color: whitesmoke;
    margin: 25px;
    padding: 25px;
    border-radius: 15px;
    text-align: center;
}

.eventDetails-speakers-container{
    margin: 25px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    column-gap: 35px;
    row-gap: 25px;
}

    .speaker-col{
        // width: 32%;
        width: 550px;
    }


    .speaker-card{
        display: flex;
        // width: 30%;
        gap: 2em;
        justify-content: space-evenly;
        background-color: white;
        padding: 25px;
    }

    .speaker-card-col{
        align-self: center;
    }

    .eventDetails-speaker-box .v-avatar{
        border: 2px solid $primary;
        background-color: $primary-light-4 !important;
        color: white;
        margin: 0px 15px 0px 15px;
    }
        .enrolled .eventDetails-speaker-box .v-avatar{
            border: 5px solid $secondary;
        }

@media only screen and (max-width: 1024px){
    .eventDetails-speaker{
        margin: 0px !important;
    }
    .eventDetails-speaker-box{
        gap: 1em;
    }
    .speaker-card{
        width: 48%;
    }
}

@media only screen and (max-width: 800px){
    .eventDetails-speakers-container{
        margin: 25px 0px 0px 0px;
    }
    .eventDetails-speakers{
        padding: 0px;
        margin: 0px;
    }

    .eventDetails-speaker-box{
        margin: 0px;
    }

    .speaker-card{
        width: 100%;
    }
}
</style>
