<template>
 <div>
        <div style="text-align: center;margin: 25px;">
            <v-icon class="event-detail-icon">mdi-bullseye-arrow</v-icon>
            <span style="font-size: 17pt;font-weight: bold;">
                <str index="events.details.learning_objectives"/>
            </span>
            <v-icon x-small class="carrot-down">mdi-triangle</v-icon>
        </div>
        <div class="eventDetails-learningObjectives">
            <div class="learningObjectives-col">
                <div class="decoration" :style="{'background-image':'URL('+images.LearningObjectives+')'}"/>
            </div>
            <div class="learningObjectives-col" v-html="eventData.learningObjectives[language]"/>
        </div>
 </div>
</template>

<script>
import LearningObjectives from "@/components/Events/assets/learningObjectives.png"
export default {
    props: {
        eventData: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    computed: {
        images: function(){
            return {
                LearningObjectives
            }
        }
    }
}
</script>

<style lang="scss">
.learningObjectives-col li{
    font-size: 18pt;
    margin: 15px;
}
</style>
<style scoped lang="scss">
.event-detail-icon{
    background-color: $primary-light-1;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    z-index: 2;
    color: white;
    margin: 15px;
    position: relative;
    top: -5px;
}






.eventDetails-learningObjectives{
    color: white;
    background-color: $primary-light-1;
    width: 100%;
    padding: 25px;
    min-height: 200px;
    display: flex;
    justify-content: center;;
}

.learningObjectives-col{
    font-size: 16pt;
    width: 50%;
}
    .learningObjectives-col:first-child{
        display: flex;
        justify-content: end;
    }

.decoration{
    background-size: cover;
    display: flex;
    justify-self: stretch;
    width: 200px;
    margin-right: 60px;
}

@media only screen and (max-width: 1024px){
    .learningObjectives-col{
        width: 100%;
    }

    .learningObjectives-col:nth-child(1){
        display: none;
    }
}
</style>
