<template>
 <v-card flat class="event-details-introduction"> 
     <v-card-title>
        <str index="events.details.introduction"/>
     </v-card-title>
     <v-card-text>
         <div v-html="eventData.introduction[language]"/>
     </v-card-text>
 </v-card>
</template>

<script>
export default {
    props: {
        eventData: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.event-details-introduction{
    margin: 25px; 
    background-color: whitesmoke;
}

@media only screen and (max-width: 800px){
    .event-details-introduction{
        margin: 0px;
    }
}
</style>