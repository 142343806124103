<template>
    <div class="eventDetails-startTime">
        <v-icon class="event-detail-icon">mdi-calendar-month</v-icon>
        <span v-if="eventData.state=='finished'" style="font-size: 17pt;font-weight: bold;">
            <str index="events.details.time.event_concluded"/>
        </span>
        <template v-else>
            <span style="font-size: 17pt;font-weight: bold;">
                <str index="events.details.time.event_starts"/>
            </span>
            <v-icon x-small class="carrot-down">mdi-triangle</v-icon>
            <div class="eventDetails-startTime-box">
                <v-icon>mdi-calendar-month</v-icon> {{eventData.eventStart}}
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        eventData: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    }
}
</script>


<style scoped lang="scss">
.eventDetails-title{
    background-color: $primary-light-1;
    color: white;
    height: 100px;
    display: flex;
    justify-content: center;
}

.eventDetails-title-content{
    align-self: center;
}

.eventDetails-startTime{
    text-align: center;
    margin: 25px;
    font-weight: bold;
}

.event-detail-icon{
    background-color: $primary-light-1;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    z-index: 2;
    color: white;
    margin: 15px;
    position: relative;
    top: -5px;
}

.eventDetails-startTime-box{
    margin: 15px auto;
    border: 1px solid lightgrey;
    max-width: 360px;
    padding: 25px;
    background: rgb(249,249,249);
    background: linear-gradient(0deg, rgba(249,249,249,1) 0%, rgba(245,245,245,1) 35%, rgba(255,255,255,1) 100%);
    color: $primary-light-1;
}
    .eventDetails-startTime-box .v-icon{
        color: $primary-light-1;
        margin: 5px;
    }





</style>
